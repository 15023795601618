import React from 'react';
import Card from './Card';
import Container from 'react-bootstrap/Container';
import './App.scss';

function App() {
  return (
    <Container className="App">
      <h1> Fake Linktree for Pit </h1>
      <Card link={"https://twitter.com/pitganart"} title={"twitter"}></Card>
      <Card link={"https://twitch.tv/piteg"} title={"twitch"}></Card>
      <Card link={"https://inprnt.com/gallery/pit"} title={"prints"}></Card>
      <Card link={"https://art.pitganart.com"} title={"art gallery"}></Card>
      <Card link={"https://comics.pitganart.com"} title={"comics"}></Card>
      <Card link={"https://ko-fi.com/pitganart"} title={"ko-fi"}></Card>
      <Card link={"https://instagram.com/pitganart"} title={"instagram"}></Card>
      <Card link={"https://threads.net/@pitganart"} title={"threads"}></Card>
      <Card link={"https://bsky.app/profile/pitganart.bsky.social"} title={"blue sky"}></Card>
    </Container>
  );
}

export default App;
