import React from 'react';
import Button from 'react-bootstrap/Button';

function Card(props : any) {
  return (
    <Button className="card">
      <a href={props.link}> {props.title}</a>
    </Button>
  );
}

export default Card;
